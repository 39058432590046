import { type User as PlatformUser } from "@/services/fobizzPlatformApi";
import { type User } from "@/services/fobizzToolsApi";
import { defineStore } from "pinia";

type UserStoreType = {
  user?: User;
  platformUser?: PlatformUser;
  accessToken?: string;
  publicToken?: string;
  visitorToken?: string;
  itemPassword?: string;
  itemPasswordRequired: boolean;
  itemPasswordErrorCount: number;
};

export const useUserStore = defineStore("user", {
  state: (): UserStoreType => {
    return {
      user: undefined,
      platformUser: undefined,
      accessToken: undefined,
      publicToken: undefined,
      visitorToken: undefined,
      itemPassword: undefined,
      itemPasswordRequired: false,
      itemPasswordErrorCount: 0,
    };
  },
  getters: {
    isAuthenticated: (state) => state.user?.authenticated,
    isVisitor: (state) => state.user?.visitor,
    isIncognito: (state) => state.user?.incognito,
    isPlatformUser: (state) => state.user?.isPlatformUser,
    isSchoolClassHost: (state) =>
      !!(state.user?.schoolClassId && !state.user?.teacherId),
    isPupil: (state) => !!(state.user?.schoolClassId && state.user.teacherId),
    hasUnreadPosts: (state) => state.platformUser?.hasUnreadPosts,
    isPlatformReviewer: (state) => state.platformUser?.reviewer,
    additionalQualificationUrl: (state) =>
      !!state.platformUser?.additionalQualificationGroup
        ?.additionalQualification &&
      !!state.platformUser?.additionalQualificationGroup?.url &&
      state.platformUser?.additionalQualificationGroup.url,
    isFeatureEnabled: (state) => (feature: string) => {
      return state.user?.enabledFeatures?.includes(feature);
    },
  },
  actions: {
    reset() {
      this.user = undefined;
      this.platformUser = undefined;
      this.accessToken = undefined;
      this.publicToken = undefined;
      this.itemPassword = undefined;
      this.itemPasswordRequired = false;
      this.itemPasswordErrorCount = 0;
    },
    clearPassword() {
      this.itemPassword = undefined;
    },
  },
});
